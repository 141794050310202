
<template>
    <div data-app class="doc-details">
        <Loader v-bind:logo="require('@/assets/images/fastmind.png')"></Loader>

        <div class="doc-header">
            <a href="#" class="btn mr-2 mb-2 btn-outline-dark"
            @click="$router.go(-1)">
                <i class="fa fa-arrow-left"></i> Back
            </a>
            <div class="doc-title px-10">
                <h4 class="text text-dark">{{ docData.title }}</h4>
                <div class="doc-tags">
                    <span class="badge bg-dark p-2 mx-2">
                        {{ docData.author }}
                    </span> 
                    <span class="badge bg-dark p-2 mx-2">
                        {{ docData.category }}
                    </span>
                </div>
            </div>
            <div class="doc-settings">
                <i class="fa fa-cog text text-dark"></i>
            </div>

        </div>
        <div class="doc-content" v-if="type == 'view'">
            <p  v-for="(words, index) in docData.words" :key="index">
                <span v-for="item in words" :key="item.id" :id="'span' + item.id"
                    :class="selectedId == item.id ? 'doc-active':''"
                    @click="progress(item.id)"
                >
                    {{ item.word }}
                </span>
            </p>
        </div>
        <div class="doc-content d-flex align-items-center" v-if="type == 'speed'" @click="clickView()">
            <div class="doc-play-shows" 
            :class="`font-36 font-family-arial text-align-start`" v-html="showingContents">
            
            </div>
        </div>
        <div class="doc-footer" v-if="type == 'view'">
            <div class="doc-play">
                <i class="far fa-play-circle" @click="changeType()"></i>
            </div>
            <div class="doc-status-bar mt-2">
                <b-progress :value="currentProgress" :max="100" height="4px" class="mb-3"></b-progress>
                <div class="doc-status-text">
                    {{minutes}} minutes to go - {{speed}} WPM
                </div>
            </div>
        </div>
        <div class="doc-footer" v-if="type == 'speed'">
            <div class="doc-play" v-if="!isPlaying">
                <i class="fa fa-angle-left" @click="back()"></i>
                <i class="far fa-play-circle mx-20" @click="play()"></i> 
                <i class="fa fa-angle-right" @click="forward()"></i>
            </div>
            <hr/>
            <div class="doc-tools">
                <div class="doc-shows">
                    {{ minutes }} minutes
                </div>
                <div class="doc-buttons">
                    <button class="btn btn-outline-secondary" size="sm">
                        <i class="fas fa-tachometer-alt"></i> {{speed}}
                    </button>
                    <button class="btn btn-outline-secondary ml-2" size="sm"  @click="changeType()">
                        <i class="fas fa-file-alt"></i> View
                    </button>
                </div>
            </div>
        </div>


        <v-dialog
            v-model="dialog"
            max-width="400"
        >
        <v-card>
            <v-card-title class="text-h4">
            <h4>
                You finished successfully
            </h4>
            </v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog=false">
                OK
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/docDetails/docDetails.scss";
</style>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import {
  GET_DOC_DATA, UPDATE_PROGRESS
} from "@/core/services/store/data.module.js";

import { mapGetters } from "vuex";
import Loader from "@/view/content/Loader.vue";

export default {
  name: "docDetails",
  components: {
    Loader
  },
 
  data() {
      return {
         id: this.$route.params.id,
         type: this.$route.params.type,
         selectedId: 0,
         progressData: null,
         minutes: 0,
         currentProgress: 0,
         showingContents: "",
         isPlaying: false,
         timer: null,

         speed: 200,
         numberOfLines: 1,
         wordsPerLine: 1,
         dialog: false,
      }
  },

  created() {
        this.getData();
  },

  beforeDestroy() {
        if(this.timer != null) {
            if (this.isPlaying) {
                this.pause();
            }
        }
  },
  methods: {
    getData() {
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        this.$store
          .dispatch(GET_DOC_DATA, this.id)
          .then(() => {
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

                if (this.docData.progress) {
                    this.progressData = this.docData.progress;
                    this.selectedId = this.docData.progress.word_id;
                    this.calculateProgress();
                    if (this.type == 'speed') {
                        if (this.progressData.status == 'finished') {
                            this.progress(0);
                        }
                        this.preparingContentForSpeed();
                    }
                } else {
                    this.progress(0);
                }

                document.getElementById(`span${this.selectedId}`).scrollIntoView({ block: 'start',  behavior: 'smooth' });
          })
          .catch(() => {
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          });
    },
    progress(word_id, status) {
        this.selectedId = word_id;
        this.calculateProgress();
        if (!this.progressData) {
            this.progressData = {
                title : this.docData.title, 
                doc_id: this.docData._id, 
                author: this.docData.author, 
                category: this.docData.category, 
                status: 'in_progress', 
                word_id
            };
        } else {
            this.progressData.word_id = word_id;
        }

        if (status) {
            this.progressData.status = status;
        }

        this.$store
          .dispatch(UPDATE_PROGRESS, this.progressData)
    },
    calculateProgress() {
        this.currentProgress = this.selectedId / this.docData.count * 100;
        this.minutes = Math.floor((this.docData.count - this.selectedId - 1) / this.speed);
    },
    changeType() {
        if (this.type == 'view') {
            this.type = 'speed';
            this.preparingContentForSpeed();
        } else {
            this.pause();
            this.type = 'view';
            this.isPlaying = false;
            setTimeout(() => {
                document.getElementById(`span${this.selectedId}`).scrollIntoView({ block: 'start',  behavior: 'smooth' });
            }, 200)
        }
    },
    clickView() {
        if (this.isPlaying) {
            this.pause();
        } else {
            this.progress(this.selectedId  + 1);
            this.preparingContentForSpeed();
        }
    },
    preparingContentForSpeed() {
        let calcLines = 0
        this.showingContents = ""
        for (var p of this.docData.words) {
            calcLines += p.length;
            if (calcLines >= this.selectedId + 1) {
                let indexOfWords = (this.selectedId) - (calcLines -  p.length);
                for (var i = 0 ; i < this.numberOfLines; i++) {
                    for (var j = 0; j < this.wordsPerLine; j++) {
                        this.showingContents += `<span>${p[indexOfWords].word}</span>`;
                    }
                    this.showingContents += "<br>"
                }
                break;
            }
        }
        this.calculateProgress()
    },
    play() {
        this.isPlaying = true;
        let sec = 60000 / this.speed * this.numberOfLines * this.wordsPerLine;
        this.timer = setInterval(function () {
            this.selectedId += this.numberOfLines * this.wordsPerLine;
            if (this.selectedId >= this.docData.count - 1) {
                this.finish();
            } else {
                this.preparingContentForSpeed();
            }
        }.bind(this), sec);
    },
    pause() {
        this.isPlaying = false;
        if(this.timer != null) {
            this.progress(this.selectedId);
            clearInterval(this.timer);
        }
    },
    finish() {
        clearInterval(this.timer);
        this.isPlaying = false;
        this.progress(0, 'finished');
        this.dialog = true;
        console.log('finished');
    },
    forward() {
        this.progress(this.selectedId + 1);
        this.preparingContentForSpeed();
    },
    back() {
        this.progress(this.selectedId - 1);
        this.preparingContentForSpeed();
    }
  },
  watch:{
  dialog:function(newValue){
    if(!newValue){
        this.$router.go(-1);
    }
  }
},
  computed: {
        ...mapGetters([
            "docData"
        ]),
   }
}
</script>